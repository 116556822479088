import { styled } from '@mui/material';
import type { Namespace, ParseKeys, TFunction } from 'i18next';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { TextButton } from '../Button/Button';
import {
  type FooterColumnLinkType,
  footerBusinessColumnLinks,
  footerEvColumnLinks,
  footerSellerColumnLinks,
} from './footerConfig';
import { FooterLink as FooterLinkComponent } from './FooterDesktop/FooterLink';

const StyledButton = styled(TextButton)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.primary,
  textDecoration: 'none',
  height: 'auto',
  '&:hover': {
    color: theme.palette.red[100],
    textDecoration: 'none',
    border: 'none !important',
  },
  '&:focus': {
    backgroundColor: 'transparent',
    textDecoration: 'none',
    border: 'none !important',
  },
}));

const FooterLink = ({
  href,
  text,
  onClick,
}: FooterColumnLinkType): React.ReactNode => {
  const { t } = useTranslation('header_footer');
  const link = href ? t(href) : '';
  if (link) {
    return (
      <FooterLinkComponent href={link} key={Math.random()}>
        {t(text)}
      </FooterLinkComponent>
    );
  }
  if (onClick) {
    return (
      <StyledButton key={Math.random()} onClick={onClick}>
        {t(text)}
      </StyledButton>
    );
  }
  return null;
};

export function getEvLinks(): Array<React.ReactNode> {
  return footerEvColumnLinks.map(FooterLink);
}

export function getBusinessTypeColumnOneLinks(): Array<React.ReactNode> {
  return footerBusinessColumnLinks.columnOne.map(FooterLink);
}

export function getBusinessTypeColumnTwoLinks(): Array<React.ReactNode> {
  return footerBusinessColumnLinks.columnTwo.map(FooterLink);
}

export function getSellerLinks(): Array<React.ReactNode> {
  return footerSellerColumnLinks.map(FooterLink);
}

export function getLinkTranslations<T extends Namespace>(
  { text, href }: FooterColumnLinkType,
  t: TFunction<T>
): { text: string; href: string } {
  return {
    text: t(text as ParseKeys<T>) as string,
    href: href ? (t(href as ParseKeys<T>) as string) : '',
  };
}
