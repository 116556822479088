import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const LinkedInIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill="currentColor" rx={2} />
      <path
        fill="#fff"
        d="M6.546 4.826c.944 0 1.722.78 1.72 1.73-.002.992-.826 1.74-1.731 1.72-.919.018-1.726-.749-1.725-1.727 0-.95.781-1.724 1.736-1.723ZM7.549 19.172H5.527a.475.475 0 0 1-.476-.475V10.07a.475.475 0 0 1 .476-.475h2.022a.475.475 0 0 1 .475.475v8.627a.475.475 0 0 1-.475.475ZM19.19 12.625a3.275 3.275 0 0 0-3.274-3.275h-.519c-1.06 0-2.036.577-2.549 1.504a.243.243 0 0 0-.027-.003V9.792a.197.197 0 0 0-.197-.197h-2.523a.197.197 0 0 0-.197.197v9.183c0 .109.089.197.197.197h2.579a.197.197 0 0 0 .197-.197V13.67c0-.917.731-1.678 1.648-1.689a1.668 1.668 0 0 1 1.688 1.668v5.327c0 .11.088.197.197.198h2.584a.197.197 0 0 0 .197-.197v-6.352Z"
      />
    </SvgIcon>
  );
};
