import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const TelephoneIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m7.17 3.001 4.06 3.97L9.12 9.786c.015.035.032.076.053.12.109.237.309.602.663 1.078.683.914 1.939 2.238 4.227 3.836l3.1-2.017L21 16.534l-2.493 4.465-.327-.022.035-.499-.035.499h-.007l-.017-.002a5.69 5.69 0 0 1-.291-.027 17.762 17.762 0 0 1-.837-.111c-.707-.11-1.69-.302-2.804-.628-2.219-.65-4.995-1.844-7.103-4.034C2.19 11.058 2.98 6.344 3.147 5.36l.042-.253L7.169 3Zm10.758 16.947 1.807-3.235-2.7-2.626-2.986 1.943-.286-.195C11.22 14.098 9.8 12.63 9.011 11.572a7.112 7.112 0 0 1-.77-1.256 3.523 3.523 0 0 1-.175-.464 1.249 1.249 0 0 1-.009-.033l-.003-.012v-.004L8.052 9.8v-.002l-.047-.218 1.88-2.509-2.9-2.837-2.868 1.516c-.185 1.229-.541 5.287 3.747 9.739 1.936 2.01 4.52 3.137 6.652 3.761 1.062.31 2 .493 2.672.598.307.048.558.08.74.1Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
