import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const PrintIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path fill="currentColor" d="M18 11.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M16.65 3h-9.3v5.008H3V18h4.35v3h9.3v-3H21V8.008h-4.35V3Zm-9.3 10.447V17H4V9.008h16V17h-3.35v-3.553h-9.3Zm1-5.44V4h7.3v4.007h-7.3Zm0 6.44V20h7.3v-5.553h-7.3Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
};
