import { styled } from '@mui/material';
import { type ParseKeys } from 'i18next';
import React, { type ReactNode } from 'react';

import { EVLogoGG } from '@/components/common/Icons/EVLogoGG';

export type FooterColumnLinkType = {
  href?: ParseKeys<'header_footer'>;
  text: ParseKeys<'header_footer'>;
  onClick?: () => void;
};

export type FooterBrandIconPathType = {
  icon: ReactNode;
  alt?: string;
  url: ParseKeys<'header_footer'>;
  className?: string;
};

const StyledImg = styled('img')(({ theme }) => ({
  height: 'auto',
  width: theme.spacing(15),
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

export const aboutLogo: FooterBrandIconPathType = {
  icon: (
    <StyledImg
      loading="lazy"
      src="https://uploadcare.engelvoelkers.com/15ef4546-f80d-4db1-a34b-bc0476f44bdb/"
      alt="E&V Logo"
    />
  ),
  url: 'logo_urls.about_logo_url',
};

export const commercialLogo: FooterBrandIconPathType = {
  icon: (
    <StyledImg
      loading="lazy"
      src="https://uploadcare.engelvoelkers.com/fe71cdb4-f4dd-4a11-b64e-7ecc838ec4a1/"
      alt="E&V Commercial Logo"
    />
  ),
  url: 'logo_urls.commercial_logo_url',
};

export const yachtingLogo: FooterBrandIconPathType = {
  icon: (
    <StyledImg
      loading="lazy"
      src="https://uploadcare.engelvoelkers.com/1d9a33ed-1af7-411b-a499-ef92f0c27bd6/"
      alt="E&V Yachting Logo"
    />
  ),
  url: 'logo_urls.yachting_logo_url',
};

export const charityLogo: FooterBrandIconPathType = {
  icon: (
    <StyledImg
      loading="lazy"
      src="https://uploadcare.engelvoelkers.com/5a52014b-9fee-4f78-9fb6-733c7f155ff1/"
      alt="E&V Charity Logo"
    />
  ),
  url: 'logo_urls.charity_logo_url',
};

export const poloSchoolLogo: FooterBrandIconPathType = {
  icon: (
    <StyledImg
      loading="lazy"
      src="https://uploadcare.engelvoelkers.com/a6da7ede-3973-495e-ad88-0d8fe8b8e3cf/"
      alt="E&V Polo School Logo"
    />
  ),
  url: 'logo_urls.polo_logo_url',
};

export const ggMagazineLogo: FooterBrandIconPathType = {
  icon: <EVLogoGG />,
  alt: 'E&V GG Magazine Logo',
  url: 'logo_urls.gg_magazine_logo_url',
  className: 'ggLogo',
};

export const careerLink: FooterColumnLinkType = {
  href: 'ev_column.career_url',
  text: 'ev_column.career',
};

export const aboutLink: FooterColumnLinkType = {
  href: 'ev_column.about_url',
  text: 'ev_column.about',
};

const locationsLink: FooterColumnLinkType = {
  href: 'ev_column.locations_url',
  text: 'ev_column.locations',
};

const blogLink: FooterColumnLinkType = {
  href: 'ev_column.blog_url',
  text: 'ev_column.blog',
};

export const pressLink: FooterColumnLinkType = {
  href: 'ev_column.press_url',
  text: 'ev_column.press',
};

const researchLink: FooterColumnLinkType = {
  href: 'ev_column.research_url',
  text: 'ev_column.research',
};

const privacyLink: FooterColumnLinkType = {
  href: 'ev_column.privacy_policy_url',
  text: 'ev_column.privacy_policy',
};

export const imprintLink: FooterColumnLinkType = {
  href: 'ev_column.imprint_url',
  text: 'ev_column.imprint',
};

const cookieHintLink: FooterColumnLinkType = {
  href: 'ev_column.cookie_hint_url',
  text: 'ev_column.cookie_hint',
};

const cookieSettingsLink: FooterColumnLinkType = {
  text: 'ev_column.cookie_settings',
  onClick: (): void => window.Didomi?.preferences?.show(),
};

export const footerEvColumnLinks: Array<FooterColumnLinkType> = [
  aboutLink,
  locationsLink,
  blogLink,
  pressLink,
  researchLink,
  privacyLink,
  imprintLink,
  cookieSettingsLink,
  cookieHintLink,
];

export const footerBrandIconsPaths: Array<FooterBrandIconPathType> = [
  aboutLogo,
  commercialLogo,
  yachtingLogo,
  charityLogo,
  poloSchoolLogo,
  ggMagazineLogo,
];

export const footerBrandIconsPathsTablet: Array<FooterBrandIconPathType> = [
  aboutLogo,
  commercialLogo,
  yachtingLogo,
  charityLogo,
  poloSchoolLogo,
  ggMagazineLogo,
];

export const footerBusinessColumnLinks: {
  columnOne: Array<FooterColumnLinkType>;
  columnTwo: Array<FooterColumnLinkType>;
} = {
  columnOne: [
    {
      href: 'business_type_column.commercial_url',
      text: 'business_type_column.commercial',
    },
    {
      href: 'business_type_column.yachting_url',
      text: 'business_type_column.yachting',
    },
    {
      href: 'business_type_column.polo_url',
      text: 'business_type_column.polo',
    },
  ],
  columnTwo: [
    {
      href: 'business_type_column.private_office_url',
      text: 'business_type_column.private_office',
    },
    {
      href: 'business_type_column.gg_journal_url',
      text: 'business_type_column.gg_journal',
    },
    {
      href: 'business_type_column.charity_url',
      text: 'business_type_column.charity',
    },
  ],
};

export const footerSellerColumnLinks: Array<FooterColumnLinkType> = [
  {
    href: 'seller_column.sell_house_url',
    text: 'seller_column.sell_house',
  },
  {
    href: 'seller_column.sell_property_url',
    text: 'seller_column.sell_property',
  },
  {
    href: 'seller_column.sell_flat_url',
    text: 'seller_column.sell_flat',
  },
  {
    href: 'seller_column.let_flat_url',
    text: 'seller_column.let_flat',
  },
  {
    href: 'seller_column.sell_multi_family_house_url',
    text: 'seller_column.sell_multi_family_house',
  },
];
