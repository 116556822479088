import { SvgIcon } from '@mui/material';
import type { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const FacebookIcon = (props: SvgIconProps): React.ReactElement => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <rect width={24} height={24} fill="currentColor" rx={2} />
      <path
        fill="#fff"
        d="M16.57 8.314v2.631h3.604l-.498 3.774H16.57V24h-3.797v-9.281H9.656v-3.774h3.117V7.766a4.188 4.188 0 0 1 4.194-4.18h3.26v3.398h-2.331a1.326 1.326 0 0 0-1.326 1.33Z"
      />
    </SvgIcon>
  );
};
